<template>
  <div>
    <window-header></window-header>
    <nav-form :onlyIcons="true"></nav-form>
    <section>
      <article>
        <field
          name="codigo"
          widget="char"
          searchable
          placeholder="Cód."
          width="67px"
          style="top: 10px; left: 10px"
          :readonly="mode == 'edit'"
          inputalign="center"
          fontweight="bold"
        />
        <field
          name="descripcion"
          widget="char"
          inline-label
          placeholder="Descripción"
          width="230px"
          style="top: 10px; left: 80px"
        />
        <field
          name="divisa_id"
          widget="m2o"
          required
          width="125px"
          style="top: 10px; left: 325px"
          :readonly="mode == 'edit'"
        />
        <field
          name="observaciones"
          widget="char"
          inline-label
          placeholder="Observaciones"
          width="195px"
          style="top: 10px; left: 455px"
        />

        <field
          ref="representantes"
          name="representantes"
          widget="handsontable"
          style="top: 45px; left: 5px"
          :height="280"
          :columns="[
            {
              name: 'representante_id',
              header: 'Representante',
              type: 'm2o',
              primary: 'codigo',
              showCode: true,
            },
            { name: 't', header: 'T' },
            {
              name: 'divisa_id',
              header: 'Divisa',
              type: 'm2o',
              primary: 'codigo',
              showCode: true,
            },
            { name: 'peso_incmerma', header: 'Inc.Merma', type: 'checkbox' },
            {
              name: 'peso_directas',
              header: 'Peso Directas',
              type: 'numeric',
              numericFormat: { pattern: '0,0.00' },
            },
            {
              name: 'peso_indirectas',
              header: 'Peso Indirectas',
              type: 'numeric',
              numericFormat: { pattern: '0,0.00' },
            },
            {
              name: 'unidad_directas',
              header: 'Unidades Directas',
              type: 'numeric',
              numericFormat: { pattern: '0,0.00' },
            },
            {
              name: 'unidad_indirectas',
              header: 'Unidades Indirectas',
              type: 'numeric',
              numericFormat: { pattern: '0,0.00' },
            },
            {
              name: 'beneficio_directas',
              header: '%/s Beneficio Directas',
              type: 'numeric',
              numericFormat: { pattern: '0,0.00' },
            },
            {
              name: 'beneficio_indirectas',
              header: '%/s Beneficio Indirectas',
              type: 'numeric',
              numericFormat: { pattern: '0,0.00' },
            },
            {
              name: 'venta_directas_metal',
              header: '%/s V.D. Metal',
              type: 'numeric',
              numericFormat: { pattern: '0,0.00' },
            },
            {
              name: 'venta_directas_metal_ni',
              header: '%/s V.D. MetalNI',
              type: 'numeric',
              numericFormat: { pattern: '0,0.00' },
            },
            {
              name: 'venta_indirectas_metal',
              header: '%/s V.I. Metal',
              type: 'numeric',
              numericFormat: { pattern: '0,0.00' },
            },
            {
              name: 'venta_indirectas_metal_ni',
              header: '%/s V.I. MetalNI',
              type: 'numeric',
              numericFormat: { pattern: '0,0.00' },
            },
          ]"
          :htSettings="htSettingsRepresentantes"
        />
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        <div class="item-title">Listado</div>
        <div class="item-after">{{ count }}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function () {
    var self = this;
    return {
      title: "Definición de comisiones",
      dbAdapter: "comision",
      primary: "codigo",
      defaultData: {
        divisa_id: self.app.config.moneda_defecto,
      },
      sequence: { name: "comision" },
      htSettingsRepresentantes: {
        nestedHeaders: [
          [
            { label: "", colspan: 10 },
            { label: "% s/VENTA", colspan: 4 },
          ],
          [
            { label: "", colspan: 10 },
            { label: "Directas", colspan: 2 },
            { label: "Indirectas", colspan: 2 },
          ],
          [
            { label: "", colspan: 3 },
            { label: "Fijo por PESO", colspan: 3 },
            { label: "Fijo por UNIDAD", colspan: 2 },
            { label: "% s/BENEFICIO", colspan: 2 },
            "Metal",
            "Metal NI",
            "Metal",
            "Metal NI",
          ],
          [
            "Representante",
            "T",
            "Divisa",
            "Inc.Merma",
            "Directas",
            "Indirectas",
            "Directas",
            "Indirectas",
            "Directas",
            "Indirectas",
            "<%100",
            "<%100",
            "<%100",
            "<%100",
          ],
        ],
        beforeChange: function (changes, source) {
          if (!changes) return;
          if (
            source == "loadData" ||
            source == "load" ||
            source == "modId" ||
            source == "empty"
          )
            return;
          var h = this;
          window.$.each(changes, function (index, change) {
            var row = change[0];
            var prop = change[1];
            var oldVal = change[2];
            var newVal = change[3];
            // Restringir campo tarifa
            if (prop == "t") {
              if (newVal == "u") changes[index][3] = "U";
              else if (newVal == "p") changes[index][3] = "P";
              else if (newVal == "b") changes[index][3] = "B";
              else if (newVal == "v") changes[index][3] = "V";
              else if (window.$.inArray(newVal, ["U", "P", "B", "V"]) == -1)
                changes[index][3] = "U";
              return;
            }
          });
        },
        cells: function (row, col, prop) {
          /*var cellProperties = {};
		      	var h = win.view.find('.field[data-name="representantes"]').find('[name="table"]').handsontable("getInstance");
		      	var t = h.getSourceData()[row]['t'];
		      	cellProperties.readOnly = false;
		      	if (t == 'U') {
		      		if($.inArray(prop, ['representante_id','t','divisa_id','unidad_directas','unidad_indirectas'])==-1) cellProperties.readOnly = true;
		      	}else if(t=='P'){
		      		if($.inArray(prop, ['representante_id','t','divisa_id','peso_incmerma','peso_directas','peso_indirectas'])==-1) cellProperties.readOnly = true;
		      	}else if(t=='B'){
		      		if($.inArray(prop, ['representante_id','t','divisa_id','beneficio_directas','beneficio_indirectas'])==-1) cellProperties.readOnly = true;
		      	}else if(t=='V'){
		      		if($.inArray(prop, ['representante_id','t','divisa_id','ventas_directas_metal','ventas_directas_metal_ni','ventas_indirectas_metal','ventas_indirectas_metal_ni'])==-1) cellProperties.readOnly = true;
		      	}

		      	return cellProperties;*/
        },
      },
    };
  },
};
</script>
