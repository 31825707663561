var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('window-header'),_c('nav-form',{attrs:{"onlyIcons":true}}),_c('section',[_c('article',[_c('field',{staticStyle:{"top":"10px","left":"10px"},attrs:{"name":"codigo","widget":"char","searchable":"","placeholder":"Cód.","width":"67px","readonly":_vm.mode == 'edit',"inputalign":"center","fontweight":"bold"}}),_c('field',{staticStyle:{"top":"10px","left":"80px"},attrs:{"name":"descripcion","widget":"char","inline-label":"","placeholder":"Descripción","width":"230px"}}),_c('field',{staticStyle:{"top":"10px","left":"325px"},attrs:{"name":"divisa_id","widget":"m2o","required":"","width":"125px","readonly":_vm.mode == 'edit'}}),_c('field',{staticStyle:{"top":"10px","left":"455px"},attrs:{"name":"observaciones","widget":"char","inline-label":"","placeholder":"Observaciones","width":"195px"}}),_c('field',{ref:"representantes",staticStyle:{"top":"45px","left":"5px"},attrs:{"name":"representantes","widget":"handsontable","height":280,"columns":[
          {
            name: 'representante_id',
            header: 'Representante',
            type: 'm2o',
            primary: 'codigo',
            showCode: true,
          },
          { name: 't', header: 'T' },
          {
            name: 'divisa_id',
            header: 'Divisa',
            type: 'm2o',
            primary: 'codigo',
            showCode: true,
          },
          { name: 'peso_incmerma', header: 'Inc.Merma', type: 'checkbox' },
          {
            name: 'peso_directas',
            header: 'Peso Directas',
            type: 'numeric',
            numericFormat: { pattern: '0,0.00' },
          },
          {
            name: 'peso_indirectas',
            header: 'Peso Indirectas',
            type: 'numeric',
            numericFormat: { pattern: '0,0.00' },
          },
          {
            name: 'unidad_directas',
            header: 'Unidades Directas',
            type: 'numeric',
            numericFormat: { pattern: '0,0.00' },
          },
          {
            name: 'unidad_indirectas',
            header: 'Unidades Indirectas',
            type: 'numeric',
            numericFormat: { pattern: '0,0.00' },
          },
          {
            name: 'beneficio_directas',
            header: '%/s Beneficio Directas',
            type: 'numeric',
            numericFormat: { pattern: '0,0.00' },
          },
          {
            name: 'beneficio_indirectas',
            header: '%/s Beneficio Indirectas',
            type: 'numeric',
            numericFormat: { pattern: '0,0.00' },
          },
          {
            name: 'venta_directas_metal',
            header: '%/s V.D. Metal',
            type: 'numeric',
            numericFormat: { pattern: '0,0.00' },
          },
          {
            name: 'venta_directas_metal_ni',
            header: '%/s V.D. MetalNI',
            type: 'numeric',
            numericFormat: { pattern: '0,0.00' },
          },
          {
            name: 'venta_indirectas_metal',
            header: '%/s V.I. Metal',
            type: 'numeric',
            numericFormat: { pattern: '0,0.00' },
          },
          {
            name: 'venta_indirectas_metal_ni',
            header: '%/s V.I. MetalNI',
            type: 'numeric',
            numericFormat: { pattern: '0,0.00' },
          } ],"htSettings":_vm.htSettingsRepresentantes}})],1)]),_c('v-collapse-wrapper',{ref:"main-collapse",attrs:{"active":true}},[_c('div',{directives:[{name:"collapse-toggle",rawName:"v-collapse-toggle"}],staticClass:"header"},[_c('div',{staticClass:"item-title"},[_vm._v("Listado")]),_c('div',{staticClass:"item-after"},[_vm._v(_vm._s(_vm.count))])]),_c('div',{directives:[{name:"collapse-content",rawName:"v-collapse-content"}],staticClass:"my-content"},[_c('hot-table',{ref:"hotTableComponent",attrs:{"settings":_vm.htSettings}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }